import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const IVRBanking = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.ivr_banking}`,
    message: (
      <>
        <FormattedMessage id="message_ivr_banking" values={commonMessageValues}/>
        <div className="ui bulleted list">
          <div className="item">{messages.message_ivr_banking_item1}</div>
          <div className="item">{messages.message_ivr_banking_item2}</div>
          <div className="item">{messages.message_ivr_banking_item3}</div>
        </div>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.ivr_banking}`} textBlocks={textBlocks}/>);
};

export default IVRBanking;